class ZIDXAccountLandingPageEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountLandingPageEditContainer";
    }
    getMatchURL(){
        return "/account/landing-pages/edit";
    }
    render(){
        // console.log("landing page edit");
        let pageBuilderOptions=new PageBuilderOptions();
        pageBuilderOptions.containerId="zidxPageBuilderEditor";
        pageBuilderOptions.formsContainerId="zidxPageBuilderForms";
        pageBuilderOptions.debugContainerId="zidxPageBuilderDebug";
        let pageBuilder=new LandingPageEditor(pageBuilderOptions);
        pageBuilder.render();
    }
}